import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      popup: {
        open: false,
        view: '',
        data: {},
      },
      promiseData: false,
    }
  },
  getters: {
    showPopup(state) {
      return state.popup.open;
    },
    popupView(state) {
      return state.popup.view;
    },
    data(state) {
      return state.popup.data;
    },
  },
  mutations: {
    openPopup(state, { view, ...data }) {
      state.popup.open = true;
      state.popup.view = view;
      state.popup.data = data;
      document.body.classList.add('no-scroll');
    },
    closePopup(state) {
      state.popup.open = false;
      state.popup.view = '';
      document.body.classList.remove('no-scroll');
    }
  },
  actions: {
    openPopup({ commit }, { view, ...data }) {
      commit('openPopup', { view, ...data });
    },
    closePopup({ commit }) {
      commit('closePopup');
    },
    sendToTg(store, data) {
      const keys = Object.keys(data);

      const formData = new FormData();
      let allowSend = true;
      keys.forEach((key) => {
        if (key === 'phone' && (data[key]?.length ?? 0) !== 18) {
          console.log(data[key]?.length);
          alert('Телефон заполнен не верно!');
          allowSend = false;
          return;
        }

        formData.append(key, data[key]);
      });

      if (allowSend && !store.promiseData) {
        store.promiseData = true;
        return fetch('/api/tg/', {
          method: 'POST',
          body: formData
        }).then(async (resp) => {
          if (resp.ok) {
            alert('Ваша заявка успешно оставлена! Ожидайте звонка')
          }
        }).catch(err => {
          console.log(err);
          alert('Возникла ошибка! Попробуйте позже');
        }).finally(() => {
          store.dispatch('closePopup');
          store.promiseData = false;
        });
      }
    }
  },
})

export default store;